import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useState } from 'react'
import BasicSelect from '../select/BasicSelect'
import GVBRRadioButton from '../gov-br-radio'
import CustomTextField from '../textfield'
import CustomAutocomplete from '../select'
import GVAutocomplete from '../autocomplete'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'

export default function GVCustomOrganizationField({ value, organizationField, changeValue, stateName, label, error, errorMessage, existAttachments, isRequired }) {

  const [text, setText] = useState(value ? value : "")
  const [radioItem, setRadioItem] = useState(value ? value : 0)
  const [selectItem, setSelectItem] = useState(value ? value : 0)
  const [checkboxItem, setCheckboxItem] = useState(value ? value : false)
  const maxLength = organizationField.maxlength ? organizationField.maxlength : 255
  Mexx2DOConsole.log("variaveis aqui")
  Mexx2DOConsole.log(value)
  Mexx2DOConsole.log(organizationField)
  Mexx2DOConsole.log(label)
  Mexx2DOConsole.log(existAttachments)
  console.log(organizationField)
  console.log(maxLength)
  console.log('maxLength gov br organization field')
  
  
  const handleChange = (fieldType, id, value, stateName) => {

    if (fieldType === 1)
      setText(value)
    else if (fieldType === 2)
      setRadioItem(value)
    else if (fieldType === 3)
      setCheckboxItem(value)
    else if (fieldType === 4)
      setSelectItem(value)

    changeValue(fieldType, id, value, stateName)
  }

  switch (organizationField.organizationfieldtype)
  {
    case 1:
      return (<CustomTextField
        name={`ofield-${organizationField.id}`}
        label={`${label && label.length > 0 ? label : organizationField.name}`}
        required={isRequired}
        fullWidth
        value={text}
        onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.value, stateName)}
        inputLabelProps={{ shrink: true }}
        inputProps={{ maxLength }}
        error={error}
        helperText={errorMessage} />)
    case 2:
      return (<GVBRRadioButton
       name={`ofield-${organizationField.id}`}
       label={`${label && label.length > 0 ? label : existAttachments ? '' : organizationField.name}`}
       options={organizationField.organizationfieldoptionsvalues ? organizationField.organizationfieldoptionsvalues : []}
       isRequired={isRequired}
       error={error}
       value={radioItem}
       errorMessage={errorMessage}
       organizationField={organizationField}
       changeValue={changeValue}
       stateName={stateName} />)
    case 3:
      return (<FormGroup>
        <FormControlLabel
          checked={checkboxItem}
          control={<Checkbox />}
          label={`${label && label.length > 0 ? label : organizationField.name}`}
          onChange={(e) => handleChange(organizationField.organizationfieldtype, organizationField.id, e.target.checked, stateName)} />
      </FormGroup>)
    case 4:
      let listOptions = organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.map(item => {
        return { id: item.id, name: item.name }
      })

      return (<GVAutocomplete
        name={`ofield-${organizationField.id}`}
        label={`${label && label.length > 0 ? label : organizationField.name}*`}
        options={listOptions}
        value={selectItem}
        handleChange={(value) => handleChange(organizationField.organizationfieldtype, organizationField.id, value, stateName)}
        error={error}
        errorMessage={errorMessage} />)
    default:
      return (<div></div>)
  }
}